import React from "react"
import { Card, Image } from "semantic-ui-react"
import "../css/testimonial.css"

const Testimonial = props => {
  const { image, title, subtitle, content, company } = props
  return (
    <Card className="testimonial">
      <Image src={image} circular size="medium" />
      <h2>{title}</h2>
      <h5>{company}</h5>
      <h3>{subtitle}</h3>
      <p>{content}</p>
    </Card>
  )
}

export default Testimonial
