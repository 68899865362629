import React from "react"
import { navigate } from "gatsby"
import { Card } from "semantic-ui-react"
import PostContent from "../components/postContent"
import TagIcons from "../components/tagIcons"
import emptyThumbnail from "../assets/img/empty-thumbnail.png"

const SidebarPost = props => {
  const { excerpt, featuredImage, slug, title, tags } = props

  return (
    <Card
      key={slug}
      onClick={() => {
        navigate(`/${slug}`)
      }}
    >
      <h2 className="header">{title}<TagIcons {...tags} /></h2>
      {featuredImage ? (
        <div
          className="img"
          style={{
            backgroundImage: `url(${featuredImage.sourceUrl})`,
          }}
        />
      ) : (
        <div
          className="img"
          style={{ backgroundImage: `url(${emptyThumbnail})` }}
        />
      )}
      <Card.Content>
        <Card.Description>
          <PostContent content={excerpt} codeMatcher={false} />
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default SidebarPost
