// import deborahPortrait from "../assets/img/deborah-portrait.jpeg"
import deborahPortrait from "../assets/img/empty-thumbnail.png"
import shamsudeenPortrait from "../assets/img/shamsudeen-portrait.jpeg"
import uchePortrait from "../assets/img/uche-portrait.jpeg"

const reviews = [
  {
    image: deborahPortrait,
    title: "Deborah Schatten",
    subtitle: "Need minor fixes for established WP website",
    content: `This is my second project with Michael, and I'd definitely hire
    him again! He's a total WordPress pro and was quickly able to
    bring a broken and very old site back to reasonable functionality
    (which was the goal of this project). He's also super easy to work
    with remotely, as he's a friendly, responsive and clear
    communicator. Additionally, he has excellent diagnostic skills,
    which made it easy to understand the site's issues and how much
    investment was worthwhile. Highly recommended.`,
    company: "Brandese"
  },
  {
    image: shamsudeenPortrait,
    title: "Shamsudeen Mustafa",
    subtitle: "Website Development",
    content: `It was an absolute pleasure to work with Michael. He went beyond
    his call of duty during the time he worked on developing our
    website. It really felt like having an extra-team member who is
    committed to the project as much as we were. He constantly came up
    with great ideas and was very patient when we changed the scope of
    the project. I really appreciated Michael's skill, commitment,
    creativity, professionalism and communication skills. I highly
    recommend Michael!`,
    company: "Correlation One"
  },
  {
    image: uchePortrait,
    title: 'Uche Nnadi',
    subtitle: "Need Co-Founder for International Tech Charity",
    content: `As a cofounder and director, Mike has been pivotal in ensuring that our Non-profits financials are sound. He has been proven capable of wearing multiple hats. He has lead marketing, design and development work and has mentored all of our engineers during the training program. I absolutely trust him to execute without fail and produce high quality work`,
    company: "Enye"
  },
]

export default reviews