import React from "react"
import { Icon } from "semantic-ui-react"
import _ from "lodash"

const TagIcons = props => {
  const { nodes } = props
  let icons

  if (nodes) {
    icons = nodes.map(tag => {
      switch (tag.name.toLowerCase()) {
        case "aws":
          return "aws"
          break
        case "css":
          return "css3 alternate"
          break
        case "html":
          return "html5"
          break
        case "javascript" || "p5" || "three":
          return "js"
          break
        case "mysql":
          return "database"
          break
        case "node":
          return "node"
          break
        case "php":
          return "php"
          break
        case "react":
          return "react"
          break
        case "vue":
          return "vuejs"
          break
        case "wordpress":
          return "wordpress"
          break
        case "digital-ocean" || "digital ocean":
          return "digital ocean"
          break
      }
    })
  }

  return (
    <span className="tag-icons" style={{ marginLeft: "15px" }}>
      {icons &&
        _.uniq(icons)
          .filter(Boolean)
          .map((iconName, index) => (
            <Icon
              key={index}
              name={iconName}
              style={{ fontSize: "24px", margin: "0px 15px" }}
            />
          ))}
    </span>
  )
}

export default TagIcons
