import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Card, Segment } from "semantic-ui-react"
import { document, window } from "browser-monads"
import "semantic-ui-css/semantic.min.css"
import "../css/homepage.css"

import Contact from "../components/contact"
import VideoBanner from "../components/videoBanner"
import FeaturedPost from "../components/featuredPost"
import SidebarPost from "../components/sidebarPost"
import Header from "../components/header"
import Testimonial from "../components/testimonials"
import reviews from "../constants/reviews"

import balloonVid from "../assets/video/balloons_edited.mp4"
import balloonBg from "../assets/img/balloons.jpg"

import correlationLogo from "../assets/img/correlation-one-logo.png"
import enyeLogo from "../assets/img/enye-logo.png"
import globalJewsLogo from "../assets/img/global-jews-logo.png"
import legalShieldLogo from "../assets/img/legal-shield-logo.png"
import theTrackLogo from "../assets/img/the-track-logo.png"
import strainConnectLogo from "../assets/img/strain-connect-logo.png"
import zaamLogo from "../assets/img/zaam-logo.png"

export const pageQuery = graphql`
  query {
    allWpPost {
      nodes {
        title
        excerpt
        featuredImage {
          sourceUrl
        }
        gallery {
          gallery {
            sourceUrl
          }
        }
        slug
        categories {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`

const pastClients = [
  {
    name: "Correlation One",
    src: correlationLogo,
    url: "https://www.correlation-one.com/",
  },
  {
    name: "Enye",
    src: enyeLogo,
    url: "https://www.enye.tech",
  },
  {
    name: "Global Jews",
    src: globalJewsLogo,
    url: "https://globaljews.org",
  },
  {
    name: "Legal Shield",
    src: legalShieldLogo,
    url: "https://www.legalshield.com/",
  },
  {
    name: "Strain Connect",
    src: strainConnectLogo,
    url: "https://www.strainconnect.com/",
  },
  {
    name: "The Track",
    src: theTrackLogo,
    url: "https://funatthetrack.com/",
  },
  {
    name: "Zaam",
    src: zaamLogo,
    url: "https://www.zaam.io/",
  },
]

// Reset Scroll
document.body.scrollTo(0, 0)

const IndexPage = ({ data }) => {
  const [featuredPosts, setFeaturedPosts] = useState()
  const [sidebarPosts, setSidebarPosts] = useState()
  const [segmentHeight, setSegmentHeight] = useState()
  const [showFeatured, setShowFeatured] = useState()

  useEffect(() => {
    setDisplayedPosts()
    window.addEventListener("resize", setDisplayedPosts, true)

    return () => {
      window.removeEventListener("resize", setDisplayedPosts, true)
    }
  }, [])

  useEffect(() => {
    setSegmentHeight(document.querySelector("#homepage-segment").clientHeight)
  }, [data, sidebarPosts])

  const setDisplayedPosts = () => {
    if (data) {
      const posts = data.allWpPost.nodes.filter(post => {
        console.log(post.categories.nodes)
        if (post.categories.nodes.length > 1) {
          return post
        }
      })
      const featuredArray = new Array(2)
      const sidebarArray = new Array(4)

      for (let i = 0; i < 6; i++) {
        if (window.innerWidth >= 1680 && i < 2) {
          featuredArray.push(posts[i])
        } else {
          sidebarArray.push(posts[i])
        }
      }

      console.log({ featuredArray })
      console.log({ sidebarArray })

      if (window.innerWidth >= 1680) {
        setFeaturedPosts(featuredArray)
        setShowFeatured(true)
      } else {
        setFeaturedPosts()
        setShowFeatured(false)
      }
      setSidebarPosts(sidebarArray)
    }
  }

  return (
    <div id="homepage">
      <div id="homepage-banner">
        <Header color="white" />
        <div id="homepage-banner-text">
          <h1>
            Getting your ideas off the ground so you can soar to new heights!
          </h1>
          <h3>Bespoke Web Applications</h3>
          <h3>Custom Wordpress Development</h3>
        </div>
        <div id="opacity-layer" />
        <VideoBanner text="" videoSrc={balloonVid} imageSrc={balloonBg} />
      </div>

      <div id="clients-wrap" className="wide">
        <div
          style={{
            margin: "30px 0px 120px 0px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Clients we've enjoyed working with!
          </h2>
          <ul id="client-list">
            {pastClients.map((client, index) => (
              <li
                key={index}
                onClick={() => {
                  window.open(client.url, "_target")
                }}
                style={{ backgroundImage: `url(${client.src})` }}
                title={client.name}
              ></li>
            ))}
          </ul>
        </div>
        <h2 style={{ textAlign: "center" }}>Featured Projects!</h2>
      </div>
      <div
        id="homepage-projects-wrap"
        className="card-wrap wide"
        style={{ width: "91%" }}
      >
        <Segment id="homepage-segment">
          {showFeatured && (
            <Card id="featured">
              {featuredPosts &&
                featuredPosts.map(post => <FeaturedPost {...post} />)}
            </Card>
          )}
          <Card id="sidebar">
            {sidebarPosts &&
              sidebarPosts.map((post, index) => <SidebarPost {...post} />)}
          </Card>
        </Segment>
      </div>
      <div
        style={{
          backgroundColor: "#555db0",
          paddingLeft: "10%",
          width: "100%",
          paddingRight: "10%",
          marginTop: `-${segmentHeight / 1.85}px`,
          paddingBottom: "60px",
          paddingTop: `${segmentHeight / 1.85}px`,
        }}
      >
        <h2 id="reviews-title" style={{ textAlign: "center", color: "white" }}>
          We make business owners happy!
        </h2>
        <div id="reviews">
          {reviews.map(reviewData => (
            <Testimonial {...reviewData} />
          ))}
        </div>
      </div>
      <Contact />
    </div>
  )
}

export default IndexPage
